
<template>
<div class="enterpriseInfo">
  <div class="card">
    <div class="title"><span class="xin">*</span>营业执照</div>
    <div class="imageBox">
      <div class="uploadItem">
        <Upload-img @handleChange="getbusinessLicenseOcr" :disabled="uploadDisabled" :imageUrl="enterpriseInfo.businessLicense" v-loading="loading"></Upload-img>
        <div class="id_msg"></div>
        <div class="reminder" v-if="Ocrhint">营业执照识别不通过</div>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="title"><span class="xin">*</span>法人身份证正反面</div>
    <div class="imageBox">
      <div class="uploadItem">
        <Upload-img @handleChange="getidCardOcr" :disabled="uploadDisabled" :imageUrl="enterpriseInfo.legalPersonIdCardBack" v-loading="loading"></Upload-img>
        <div class="id_msg">人像</div>
      </div>
      <div class="uploadItem">
        <Upload-img @handleChange="getidCardBack" :disabled="uploadDisabled" :imageUrl="enterpriseInfo.legalPersonIdCardFront" v-loading="loading"></Upload-img>
        <div class="id_msg">国徽</div>
      </div>
      <div class="reminder" v-if="Cardhint">身份证识别不通过</div>
    </div>
  </div>
  <div class="person">
    <div>是否经办人办理</div>
    <el-switch v-model="isPerson" class="defineSwitch" inline-prompt  active-text="是" inactive-text="否">
    </el-switch>
  </div>
  <div class="card" v-if="isPerson">
    <div class="title"><span class="xin">*</span>经办人授权书</div>
    <div class="power">
      <div @click="showPowerInfo" :class="powerInfo.name?'link':'name' ">{{powerInfo.name || '授权书'}}</div>
      <el-upload
          class="upload-demo"
          action
          :http-request="uploadFile"
          :show-file-list="false">
        <el-button type="text">选择上传</el-button>
      </el-upload>
    </div>
  </div>
  <div class="card" v-if="isPerson">
    <div class="title"><span class="xin">*</span>经办人身份证正反面</div>
    <div class="imageBox">
      <div class="uploadItem">
        <Upload-img @handleChange="getAgentIdBack" :disabled="uploadDisabled" :imageUrl="enterpriseInfo.agentIdCardBack" v-loading="loading"></Upload-img>
        <div class="id_msg">人像</div>
      </div>
      <div class="uploadItem">
        <Upload-img @handleChange="getAgentIdFront" :disabled="uploadDisabled" :imageUrl="enterpriseInfo.agentIdCardFront" v-loading="loading"></Upload-img>
        <div class="id_msg">国徽</div>
      </div>
      <div class="reminder" v-if="Cardhint">身份证识别不通过</div>
    </div>
  </div>
  <div class="formData">
    <el-form ref="formInfo" size="small" :model="form" :rules="rules" label-suffix="：" label-width="150px"  class="formInfo" :disabled="uploadDisabled">
      <el-form-item label="企业名称" prop="enterpriseName">
        <el-input placeholder="企业名称" v-model="form.enterpriseName"></el-input>
      </el-form-item>
      <el-form-item label="统一社会信用代码" prop="socialCreditCode">
        <el-input placeholder="社会信用代码" v-model="form.socialCreditCode"></el-input>
      </el-form-item>
      <el-form-item label="企业地址" prop="address" class="specialItem">
        <el-input placeholder="地址" v-model="form.address"></el-input>
      </el-form-item>
      <el-form-item label="法人姓名" prop="legalPersonIdCardName">
        <el-input placeholder="姓名" v-model="form.legalPersonIdCardName"></el-input>
      </el-form-item>
      <el-form-item label="法人身份证号" prop="legalPersonIdCardNumber">
        <el-input placeholder="身份证号码" v-model="form.legalPersonIdCardNumber"></el-input>
      </el-form-item>
      <el-form-item label="法人手机号" prop="legalPersonPhone">
        <el-input placeholder="法人手机号" v-model="form.legalPersonPhone">
          <el-button slot="append" type="text" @click="getVerifyCode()" :loading="downCode != 120"><span class="verify">{{downCode ==120 ? "获取验证码" :`${downCode}s` }}</span></el-button>
        </el-input>
      </el-form-item>
      <el-form-item label="验证码" prop="verCode">
        <el-input placeholder="验证码" v-model="form.verCode"></el-input>
      </el-form-item>
      <el-form-item label="法人联系地址" prop="legalPersonAddress">
        <el-input placeholder="法人联系地址" v-model="form.legalPersonAddress"></el-input>
      </el-form-item>
      <el-form-item label="约定账单日" prop="appointBillDate">
        <el-select placeholder="约定账单日" v-model="form.appointBillDate" style="width:100%">
          <el-option v-for="item in 28" :key="item" :label="item" :value="`${item}`"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="约定的支付周期" prop="appointPaymentCycle">
        <el-select placeholder="约定的支付周期" v-model="form.appointPaymentCycle" style="width:100%">
          <el-option v-for="item in paymentList" :key="item.label" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div class="errTips">请仔细核对信息！！！确认无误后再提交</div>
  </div>
  <div class="buttonGroup">
    <template>
      <el-button size="small" type="primary" @click="handleSubmitInfo('addInfo')"  :loading="btnLoading">提交企业信息</el-button>
    </template>
  </div>
</div>
</template>
<script>
import {putFile} from "@/api/userInfoPort";
import {addEnterpriseInfo, legalPersonVerCode} from "@/api/enterprise";

export default {
  name:'enterpriseInfo',
  data(){
    const validateName = (rule, value, callback) => {
      let { legalPerson } = this.enterpriseinfo
      if (value === '') {
        callback(new Error('请输入法人姓名！'))
      } else if (value !== legalPerson) {
        callback(new Error('输入的法人姓名与营业执照不一致！'))
      } else {
        callback()
      }
    }
    return {
      timer: null,
      downCode:120,
      powerInfo:{
        authorizationUrl:"",
        name:''
      },
      isPerson:false,
      loading:false,
      Ocrhint: false,
      Cardhint: false,
      uploadDisabled:false,
      enterpriseInfo: {},
      currentPage:1,
      form: {
        appointBillDate: '',
        appointPaymentCycle: '',
        legalPersonIdCardName: '',
        legalPersonIdCardNumber: '',
        enterpriseEmail: '无',
        legalPersonPhone: '',
        legalPersonEmail: '无',
        enterpriseName: '',
        address: '',
        legalPerson: '',
        socialCreditCode: '',
        legalPersonAddress: ''
      },
      rules: {
        enterpriseName: [{ required: true, message: '请输入企业名称', trigger: 'blur' }],
        socialCreditCode: [{ required: true, message: '请输入统一社会信用代码', trigger: 'blur' }],
        address: [{ required: true, message: '请输入企业地址', trigger: 'blur' }],
        legalPersonIdCardName: [{ required: true, validator: validateName, trigger: 'blur' }],
        legalPersonIdCardNumber: [{ required: true, message: '请输入法人身份证号', trigger: 'blur' }],
        legalPersonPhone: [{ required: true, message: '请输入法人手机号', trigger: 'blur' }],
        legalPersonAddress: [{ required: true, message: '请输入法人联系地址', trigger: 'blur' }],
        appointBillDate: [{ required: true, message: '请选择约定的账单日', trigger: 'blur' }],
        appointPaymentCycle: [{ required: true, message: '请输入约定的支付周期', trigger: 'blur' }],
        verCode: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
      },
      paymentList: [
        {
          label: '月付',
          value: '0'
        },
        {
          label: '季付',
          value: '1'
        },
        {
          label: '半年付',
          value: '2'
        },
        {
          label: '年付',
          value: '3'
        }
      ],
      // 合同签署状态
      contractSignStatus: false,
      // 控制按钮是否被点击状态
      btnLoading: false
    }
  },
  methods:{
    getbusinessLicenseOcr(url) {
      this.loading = true
      var formData = new FormData()
      formData.append('fileUrl', url)
      this.$axios({
        url: '/zuwu-api/web/enterprise/businessLicenseOcr',
        method: 'POST',
        data: formData,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
          .then((res) => {
            this.loading = false
            if (res.data.code == 200) {
              this.Ocrhint = false
              this.enterpriseInfo = res.data.data
              this.enterpriseInfo.businessLicense = url
              this.form.enterpriseName = res.data.data.enterpriseName
              this.form.legalPerson = res.data.data.legalPerson
              this.form.socialCreditCode = res.data.data.socialCreditCode
              this.form.address = res.data.data.address
              this.$message({
                message: '上传成功',
                type: 'success'
              })

              this.handleChangeBar()
            } else {
              this.$message({
                message: res.data.msg,
                type: 'warning'
              })
              this.enterpriseInfo.businessLicense = ''
              this.Ocrhint = true
            }
          })
          .catch((err) => {
            this.$message.error(err.data.msg)
          })
    },
    getidCardOcr(url) {
      this.loading = true
      var formData = new FormData()
      formData.append('fileUrl', url)
      this.$axios({
        url: '/zuwu-api/web/enterprise/idCardOcr',
        method: 'POST',
        data: formData,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
          .then((res) => {
            this.loading = false
            if (res.data.code == 200) {
              this.Cardhint = false
              this.form.legalPersonIdCardName = res.data.data.name
              this.form.legalPersonIdCardNumber = res.data.data.idCardNumber
              this.form.legalPersonAddress = res.data.data.address

              this.enterpriseInfo.legalPersonIdCardBack = url
              this.$message({
                message: '上传成功',
                type: 'success'
              })
              this.handleChangeBar()
            } else {
              this.$message({
                message: res.data.msg,
                type: 'warning'
              })
              this.enterpriseInfo.legalPersonIdCardBack = ''
              this.Cardhint = true
            }
          })
          .catch((err) => {
            return Promise.reject(err)
          })
    },
    getidCardBack(url) {
      this.enterpriseInfo.legalPersonIdCardFront = url
      this.handleChangeBar()
    },
    handleChangeBar() {
      let { businessLicense, legalPersonIdCardBack, legalPersonIdCardFront, letterOfAuthorization } = this.enterpriseInfo
      let curData = {
        businessLicense,
        legalPersonIdCardBack,
        legalPersonIdCardFront,
        letterOfAuthorization
      }
      let list = Object.values(curData)
      let len = list.length
      switch (len) {
        case 0:
          this.progress = 0
          break
        case 1:
          this.progres = 12
          break
        case 2:
          this.progress = 24
          break
        case 3:
          this.progress = 36
          break
        case 4:
          this.progress = 48
          break

        default:
          break
      }
    },
    handleSubmitInfo(type) {
      this.$refs['formInfo'].validate((valid) => {
        if (valid) {
          this.btnLoading = true
          let data = Object.assign(this.enterpriseInfo, this.form, { guarantorType: this.guarantorType, lesseeType: 0, legalPerson: this.form.legalPersonIdCardName })
          addEnterpriseInfo(data).then((res) => {
                if (res.data.code == 200) {
                  if (type == 'addInfo') {
                    this.enterpriseInfo = {
                      ...this.enterpriseInfo,
                      id: res.data.data
                    }
                    this.currentpage = 3
                  } else if (type == 'sign') {
                    this.getSignedStatusAndUrl(res.data.data)
                  } else if (type == 'view') {
                    this.$emit('materials', { msg: '', isShow: false })
                  } else if (this.source == 'User') {
                    this.$emit('materials', { msg: '绑定企业成功', isShow: true })
                  }
                } else {
                  this.$message({
                    showClose: true,
                    message: res.data.msg,
                    type: 'warning'
                  })
                }
                setTimeout(() => {
                  this.btnLoading = false
                }, 1000)
              })
              .catch((err) => {
                this.btnLoading = false
                return Promise.reject(err)
              })
        }
      })
    },
    uploadFile(item){
      console.log(item)
      let fileExt = item.file.name.split('.').pop().toLowerCase()
      if (fileExt !== 'jpg' && fileExt !== 'jpeg' && fileExt !== 'png' && fileExt !== 'pdf') {
        this.$message.error('仅支持jpg,jpeg,png,pdf格式！')
        return false;
      }
      let FormDatas = new FormData()
      FormDatas.append('file', item.file)
      putFile(FormDatas).then(res=>{
        if(res.data.code == 200){
          this.powerInfo.authorizationUrl = res.data.data.link
          this.powerInfo.name = res.data.data.originalName
        }
      })
    },
    showPowerInfo(){
      if(this.powerInfo.name){
        window.open(this.powerInfo.authorizationUrl, '_blank')
      }
    },
    getAgentIdBack(url){
      this.enterpriseInfo.agentIdCardBack = url
    },
    getAgentIdFront(url){
      this.enterpriseInfo.agentIdCardFront = url
    },
    getVerifyCode(){
      let userNameRule = /^1[3-9]\d{9}$/
      if(userNameRule.test(this.form.legalPersonPhone)){
        legalPersonVerCode({legalPersonPhone:this.form.legalPersonPhone}).then(({data})=>{
          if(data.code==200){
            this.$message.success('发送成功')
            this.timer =  setInterval(()=>{
              this.downCode--
              if(this.downCode==0){
                this.downCode = 120
                clearInterval(this.timer)
              }
            },1000)
          }else{
            this.$message.warning(data.msg)
          }
        })
      }else{
        this.$message.warning('请输入正确的手机号！')
      }
    },
  }
}
</script>
<style scoped lang="scss">
.enterpriseInfo {
  max-width: 1430px;
  margin: 20px auto;
  .card{
    background: #fff;
    margin-bottom: 20px;
    padding: 20px;
    .title{
      border-bottom: 1px solid #dae1e8;
      line-height: 40px;
      .xin{
        color: red;
        margin-right: 5px;
      }
    }
    .imageBox{
      display: flex;
      margin-top: 20px;
      .uploadItem{
        width: 30%;
        margin-right: 20px;
        .id_msg ,.reminder{
          text-align: center;
          margin-top: 10px;
        }
        .reminder{
          color: red;
        }
      }
    }
    .power{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 20px 0 20px;
      .link{
        color: #409eff;
        cursor: pointer;
      }
      .name{
        color: #5e5e5e;
      }
    }
  }
  .formData ,.buttonGroup, .person{
    background: #fff;
    padding: 20px;
    margin-bottom: 20px;
    .errTips{
      color: red;
      padding-left: 20px;
      font-size: 14px;
    }
  }
  .person{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    .defineSwitch{
      ::v-deep .el-switch__label--left {
        position: relative;
        left: 50px;
        color: #fff;
        z-index: -1111;
      }
      ::v-deep .el-switch__core{
        width: 50px!important;
      }
      ::v-deep .el-switch__label--right {
        position: relative;
        right: 50px;
        color: #fff;
        z-index: -1111;
      }
      ::v-deep .el-switch__label--right.is-active {
        z-index: 1111;
        color: #fff!important;
      }
      ::v-deep .el-switch__label--left.is-active {
        z-index: 1111;
        color: #9c9c9c!important;
      }
    }
  }
  .buttonGroup{
    text-align: center;
  }
}
</style>