import Axios from 'axios'

// 获取验证码
export const legalPersonVerCode = (data) => Axios({
    url: '/zuwu-api/web/enterprise/legalPersonVerCode',
    method: 'post',
    data
});
// 添加企业信息
export const addEnterpriseInfo = (data) => Axios({
    url: '/zuwu-api/web/enterpriseInfo/addEnterpriseInfo',
    method: 'post',
    data
});